@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    /* font-family: Futura, system-ui, sans-serif; */
    font-family: "Futura";
    /* src: url("./assets/fonts/futura/futura-light-bt.ttf") format("truetype"); */
    src: url("./assets/fonts/futura/FuturaBookFont.ttf") format("truetype");
  }
}
